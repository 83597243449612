import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.

window.$ = $;
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';


$(document).foundation();

$('body').stop(true, true).delay(500).animate({'opacity': 1}, {duration: 400, alway: function(){ $('body').css({'opacity': 1}) }});

// SVG to PNG fallback
if(!Modernizr.svg) {
    $('img[src*="svg"]').attr('src', function() {
        return $(this).attr('src').replace('.svg', '.png');
    });
} else {
  /*  Replace all SVG images with inline SVG */
  $('img.svg').each(function(){
    var $img = $(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    $.get(imgURL, function(data) {
       //Get the SVG tag, ignore the rest
      var $svg = $(data).find('svg');
       //Add replaced image\'s ID to the new SVG
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
       //Add replaced image\'s classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass+' replaced-svg');
      }
       //Remove any invalid XML tags as per http:validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');
       //Replace image with new SVG
      $img.replaceWith($svg);
    });
  });
}

let heroAnimationStarted = false,
    resizeTime,
    timeout = false,
    delta = 200;
$(window).on('resize', function(e){
  resizeTime = new Date();
  if (timeout === false) {
    timeout = true;
    setTimeout(makeResize, delta);
  }
});
function makeResize() {
  if (new Date() - resizeTime < delta) {
    setTimeout(makeResize, delta);
  } else {
    timeout = false;
    $('.boxes .img img').trigger('bestfit');
  }
}

if ($('html').hasClass('no-touchevents') && $('body').hasClass('HomePage')) {
  $(document).on('click', '.tabs-title>a', function(event) {
    //console.log($(this).data('tabs-target'));
    var data_target = $(this).data('tabs-target');
    for(var c = 0; c < tabs.length; c++) {
      if ($(tabs[c]).data('tabs-target') == data_target) {
        curr_tab_num = c;
        break;
      }
    }
    var targets = data_target.split(',');
    if ($('#' + targets[0]).hasClass('is-active')) {
      return false;
    } else {
      clearInterval(tabs_interval);
      var active_tabs = $('[data-tabs-agiva]>li.is-active');
      active_tabs.removeClass('is-active');
      active_tabs.find('a').attr('aria-selected', false);

      var curr_tab = $('[data-tabs-target="'+data_target+'"]');
      curr_tab.parent().addClass('is-active');
      curr_tab.attr('aria-selected', true);
    }
    // var tabs = $('[data-tabs-agiva]>li');
    // var contents = $('[data-tabs-content-agiva]>div');
    var active_contents = $('[data-tabs-content-agiva]>div.is-active');
    active_contents.removeClass('is-active');
    TweenLite.to(active_contents, 0, {alpha: 0});

    for(var t = 0; t < targets.length; t++) {
      $('#'+targets[t]).addClass('is-active');
      TweenLite.to($('#'+targets[t]), 0.4, {alpha: 1, ease: Power2.easeOut});
    }
    runTimer();
    return false;
  });
  runTimer();
} else {
  var contents = $('[data-tabs-content-agiva]>div');
  contents.addClass('is-active');
  $('.tabs').hide();
}

// if (document.querySelector(".boxes")) {

  $('.boxes .img img').on('bestfit', function() {
    var css;
    var ratio = $(this).width() / $(this).height();
    var pratio = $(this).parents(".image-box").width() / $(this).parents(".image-box").height();
    if (ratio >= pratio) {
      css = {width:'auto', height:'100%'};
    } else {
      css = {width:'100%', height:'auto'};
    }
    $(this).css(css);
    // gsap.to(document.querySelector(".hero-wrapper__content .text"), {duration: 1, alpha: 1, delay: 0.9, onComplete: handwriteWords});
  }).on('load', function() {
    $(this).trigger('bestfit');
  }).trigger('bestfit');

// }




/* FILTERS */
let filters = $('#FiltersMenu'),
    queryParameters = {},
    queryString = location.search.substring(1);
if (filters.length) {
  let cats = [];
  $('#FiltersMenu [type="checkbox"]').each(function(idx, el){
    if ($(el).is(':checked')) {
      cats.push({param: el.dataset.id, val: true});
      // locationQuery([{param: el.dataset.id, val: true}]);
    }
  });
  if (cats.length) locationQuery(cats);
  $('#FiltersMenu').on('click', 'input[type="checkbox"]', function(idx){
    // let val = $(idx.target).is(':checked');
    // locationQuery([{param: idx.target.dataset.id, val: val}]); // setting your param

    let cats2 = [];
    $('#FiltersMenu [type="checkbox"]').each(function(idx2, el2){
      if ($(el2).is(':checked')) {
        cats2.push({param: el2.dataset.id, val: true});
        // locationQuery([{param: el.dataset.id, val: true}]);
      } else {
        cats2.push({param: el2.dataset.id})
      }
    });
    if (cats2.length) locationQuery(cats2);

  });
  $('#FiltersMenu').on('click', 'input[type="radio"]', function(idx){
    locationQuery([{param: idx.target.name, val: 'radio'}], idx.target.dataset.id); // setting your param
    location.search = $.param(queryParameters);
  });
  $('#FiltersMenu .button-apply').on('click', function(evt){
    location.search = $.param(queryParameters);
  });
  $('#FiltersMenu .button-reset').on('click', function(evt){
    let inputs = $(this).parents('.submenu').find('[type="checkbox"]');
    inputs.each(function(idx, el){
      $(el).prop('checked', false);
      delete queryParameters[el.dataset.id];
    });
    location.search = $.param(queryParameters);
  });
  $('#FiltersMenu a.can-clear').on('click', function(evt){
    if ($(evt.target).hasClass('clear-option')) {
      evt.stopPropagation();
      let toDelete = $(evt.target).parent('.button').next().find("[type='radio']").first().prop("name");
      // console.log(toDelete);

      if (toDelete) {
        queryString.split("&").forEach(function(idx){
          let prm = idx.split("=")[0];
          let val = idx.split("=")[1];
          if (prm == toDelete) locationQuery([{param: prm}]);
        });
      } else {
        locationQuery([{param: 'sdate'}, {param: 'edate'}, {param: 'date'}]);
      }
      location.search = $.param(queryParameters);
      return false;
    }
  });
  // $('#FiltersMenu select').on('change', function(evt){
  //   // console.log($(this).attr("id"));
  //   locationQuery([{param: $(this).attr("id"), val: $(this).val()}], $(this).val()); // setting your param
  //   // console.log(queryParameters)
  //   location.search = $.param(queryParameters);
  // });
}
$(".reveal-filters").on("click", function(event){
  let flt = new Foundation.Reveal($(".filter-popup"), {appendTo: "header"});
  flt.open();
});

// function locationQuery(parameter, value, urivalue) {
function locationQuery(params_array, urivalue) {
  /*
   * queryParameters -> handles the query string parameters
   * queryString -> the query string without the fist '?' character
   * re -> the regular expression
   * m -> holds the string matching the regular expression
   */
  let re = /([^&=]+)=([^&]*)/g, m;

  // Creates a map with the query string parameters
  while (m = re.exec(queryString)) {
    // console.log(m)
    // queryParameters[decodeURIComponent(m[1])] = (m[1] != 'start') ? Boolean(decodeURIComponent(m[2])) : decodeURIComponent(m[2]);
    queryParameters[decodeURIComponent(m[1])] = (m[1] != 'start') ? decodeURIComponent(m[2]) : decodeURIComponent(m[2]);
  }
  // Add new parameters or update existing ones
  console.log(params_array);
  for (let p = 0; p < params_array.length; p++) {
    let parameter = params_array[p].param, value = params_array[p].val;
    if (value == true || value == "true") {
      queryParameters[parameter] = value;
    } else if (urivalue != undefined && urivalue != "") {
      // console.log(urivalue)
      queryParameters[parameter] = urivalue;
    } else {
      delete queryParameters[parameter];
    }
  }

  /*
   * Replace the query portion of the URL.
   * jQuery.param() -> create a serialized representation of an array or
   *     object, suitable for use in a URL query string or Ajax request.
   */
  // console.log(queryParameters);
  // location.search = $.param(queryParameters); // Causes page to reload
}
/* FILTERS */



import {TweenLite, Power2} from 'gsap';
var tabs = $('[data-tabs-agiva]>li>a');
var contents = $('[data-tabs-content-agiva]>div');
var curr_tab_num = 0;
var tabs_count = tabs.length;
var tabs_interval;
var carousel_time = 10;// sec
TweenLite.to($('[data-tabs-content-agiva]>div'), 0, {alpha: 0});
TweenLite.to($('[data-tabs-content-agiva]>div.is-active'), 0.4, {alpha: 1, ease: Power2.easeOut, delay: 0.4});
function runTimer(){
  tabs_interval = setInterval(function() {
    clearInterval(tabs_interval);
    
    if(++curr_tab_num > tabs_count){
      curr_tab_num = 0;
    }
    $(tabs[curr_tab_num]).trigger('click');
    
  }, carousel_time*1000);
}


window.initMap = function() {
  var map,
      mapOptions,
      marker,
      markerOptions,
      dom = document.getElementById('gmap');
  if (!dom) return false;
  var locX = parseFloat(dom.getAttribute("data-locx"));
  var locY = parseFloat(dom.getAttribute("data-locy"));

  mapOptions = {
    zoom: 12,
    scrollwheel: false,
    center: {lat: locX, lng: locY},
    //styles: mapStyle,
    mapTypeControl: false,
    zoomControl: true,
    zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
    },
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
    },
    fullscreenControl: false
  }
  map = new google.maps.Map(document.getElementById('gmap'), mapOptions);
  markerOptions = {
    position: {lat: locX, lng: locY},
    map: map
  };
  marker = new google.maps.Marker(markerOptions);

}
function initLoader() {
  var script = document.createElement("script");
  // script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBXgxfkIZN2EGf90T9rE2G0OMWBb0k0nFQ&callback=initMap";//dev server
  script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBSTjo6-3YZ4Lk0ugzMP08A_uJe5s6HL-c&callback=initMap";
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  document.getElementsByTagName("head")[0].appendChild(script);
}
//var map,
//    project = {lat: 43.2577764, lng: 27.8099393}; // Aksakovo id
if (document.getElementById('gmap')) window.onload = initLoader;


let closeBtn = document.querySelector(".close-button"),
    popup = document.getElementById("SubscribePanel");
closeBtn.addEventListener("click", function(ev) {
  $(popup).foundation("toggle");
});

require('@fancyapps/fancybox');
// let popUp = document.querySelector("a[data-fancybox]")